import React, { lazy, Suspense } from "react";
import { Outlet, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { SC_Roles, SC_Routes, isValidPageWithoutAuthorization, invalidPage } from "./containers/Data";
import { LoaderComponent } from "./components/CustomizedPageActions";
const NotFound = lazy(() => import("./containers/NotFound"));
const Home = lazy(() => import("./containers/Home"));
const Login = lazy(() => import("./containers/Login"));
const FreeTrialSignUp = lazy(() => import("./containers/FreeTrialSignup"));
const UserAccounts = lazy(() => import("./containers/UserAccounts"));
const Callouts = lazy(() => import("./containers/Callouts"));
const Transactions = lazy(() => import("./containers/Transactions"));
const Swagger = lazy(() => import("./containers/Swagger"));
const QuickStartTrial = lazy(() => import("./containers/QuickTrial"));
const Clients = lazy(() => import('./containers/Clients'));

export default function AppRoutes({ appProps }) {
  const SE_Env =
    process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "SE";
  const location = useLocation();

  function EmptyContainerLoader() {
    return (
      <div className="empty-loading-container" id="empty-container">
        {LoaderComponent("empty-container")}
      </div>
    );
  }


  function PageNotFound() {
    return (
      <div style={{ textAlign: "center", marginTop: '250px' }} >
        <h1>404, Page Not Found</h1>
        <p>The page you are looking for might not exist,<br />or maybe its temporarily unavailable.</p>
      </div>
    );
  }
  // Add a condition to check if the user is authorized
  const isAuthorized = appProps.isAuthenticated;


  // If the user is not authorized and not on the login page and free trail signup page it redirect to the login page
  if (!isAuthorized && !Object.values(isValidPageWithoutAuthorization).includes(location.pathname)) {
    if (Object.values(invalidPage).includes(location.pathname)) {
      return <PageNotFound />
    }
    else {
      return (
        <Login {...appProps} />
      )
    }
  }
  //TO show proper Error message when user try to access  unauthorised pages directly
  if (isAuthorized && location.pathname !== "/" && !Object.values(SC_Routes).includes(location.pathname)) {
    return (
      <PageNotFound />
    )
  }

  return (
    <Suspense fallback={<EmptyContainerLoader />}>
      <Outlet />
      <Routes>

        {/* UnauthenticatedRoute */}
        <Route
          path="/login"
          element={
            appProps.isAuthenticated ? (
              <Navigate to="/" replace />
            ) : (
              <Login {...appProps} />
            )
          }
        />

        {/* AuthenticatedRoute */}
        <Route
          path="/"
          element={
            (() => {
              if (appProps.isAuthenticated) {
                return (
                  SE_Env &&
                    appProps.role === SC_Roles.QuickStart &&
                    appProps.trialVars.trialInProgress ? (
                    <QuickStartTrial {...appProps} />
                  ) : (
                    <Home {...appProps} />
                  )
                )
              }
              else {
                return (
                  <Navigate
                    to={`/login?redirect=${location.pathname}${location.search}`}
                    replace
                  />
                )
              }
            })()
          }
        />

        {SE_Env && (
          <Route
            path={SC_Routes.FreeTrialSignup}
            element={<FreeTrialSignUp {...appProps} />}
          />
        )}

        <Route path={SC_Routes.Dashboards} element={<Home {...appProps} />} />

        <Route path={SC_Routes.Clients} element={<Clients {...appProps} />} />
        <Route path={SC_Routes.Callouts} element={<Callouts {...appProps} />} />

        {appProps.role !== SC_Roles.QuickStart && (
          <Route
            path={SC_Routes.UserAcc}
            element={<UserAccounts {...appProps} />}
          />
        )}

        <Route path={SC_Routes.Transactions} element={<Transactions {...appProps} />} />

        <Route path={SC_Routes.Swagger} element={<Swagger {...appProps} />} />

        {SE_Env && (appProps.role === SC_Roles.QuickStart || appProps.role === "") && (
          <Route
            path={SC_Routes.QuickStart}
            element={<QuickStartTrial {...appProps} />}
          />
        )}

        {/* Finally, catch all unmatched routes */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
